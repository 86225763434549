import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class StepComponent implements OnInit {
  @Input() title: string = 'Step';
  @Input() active = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
