<div class="stepper">
  <ol class="step-list mb-3 mb-md-6 mt-md-3" [class.pb-6]="showLabels" #steplist>
    <li class="step-list__item" *ngFor="let tab of tabs; let i = index">
      <button
        class="stepper-button stepper-button--line"
        (click)="selectTab(tab, i, stepButton)"
        [disabled]="(disableNextTabs && i > activeIndex) || tab.disabled"
        #stepButton
        [class.is--finished]="i <= activeIndex"
        [class.is--active]="tab.active"
      >
        <span class="stepper-button__index sr-only">{{ i + 1 }}</span>
        <span class="stepper-button__title fs-body-xxxs d-none d-md-inline-block" [class.sr-only]="!showLabels">{{ tab.title }}</span>
      </button>
    </li>
  </ol>
  <ng-content></ng-content>
</div>
