import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepComponent } from '../step/step.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class StepperComponent implements AfterContentInit, OnChanges, AfterViewInit, OnDestroy {
  @ContentChildren(StepComponent) tabs?: QueryList<StepComponent>;
  @ViewChildren('stepButton') stepButtons?: QueryList<ElementRef>;
  @ViewChild('steplist') tabList?: ElementRef;
  @Input() activeTab: number = 0;
  @Input() disableNextTabs: boolean = false;
  @Input() showLabels = false;
  @Output() selectedTab = new EventEmitter<number>();

  private _unsubscribe$ = new Subject<void>();

  activeIndex = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['activeTab'] && this.tabs?.length && this.tabs?.length > 0) {
      const index = changes['activeTab'].currentValue;
      this.selectTab(this.tabs.toArray()[index], index, false);
    }
  }
  // contentChildren are set
  ngAfterContentInit() {
    if (this.tabs) {
      this.selectTab(this.tabs.toArray()[this.activeTab], this.activeTab, false);
    }
  }

  ngAfterViewInit() {
    if (this.tabs) {
      this.tabs.changes.pipe(takeUntil(this._unsubscribe$)).subscribe(queryChanges => {
        this.cd.detectChanges();
      });
    }
  }

  go(direction: 'next' | 'prev' | number) {
    const tabs = this.tabs?.toArray();
    if (!tabs) {
      return;
    }
    if (direction === 'next') {
      this.activeIndex = this.activeIndex < tabs.length ? this.activeIndex + 1 : this.activeIndex;
    } else if (direction === 'prev') {
      this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : 0;
    } else {
      this.activeIndex = direction;
    }

    this.selectTab(tabs[this.activeIndex], this.activeIndex);
    this.cd.detectChanges();
  }

  selectTab(tab: StepComponent, index: number, tabEl: any = null, emit = true) {
    if (!this.tabs) {
      return;
    }
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => (tab.active = false));

    const tabButton = this.stepButtons ? this.stepButtons.toArray()[index]?.nativeElement : null;
    if (tabButton && isPlatformBrowser(this.platformId) && this.activeIndex !== index && !this.isInViewport(tabButton)) {
      window.scrollTo({ top: tabButton.offsetTop, behavior: 'smooth' });
    }

    this.activeIndex = index;

    if (tabButton && this.tabList) {
      this.tabList.nativeElement.scrollTo({ left: tabButton?.offsetLeft, behavior: 'smooth' });
    }

    // activate the tab the user has clicked on.
    tab.active = true;

    if (emit) {
      this.selectedTab.emit(index);
    }
  }

  isInViewport(el: any) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
